<template>
  <div>
    <div class="backClass"><a-button type="primary" @click="handleCancel"> 返回 </a-button></div>
    <a-form-model ref="ruleForm" :model="form" :rules="rules">
      <a-form-model-item :labelCol="{ span: 2 }" :wrapper-col="{ span: 8 }" label="弹窗类型" prop="typeId">
        <a-select
          v-model="form.typeId"
          placeholder="请选择"
          :disabled="disableType"
          @change="typeChange"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option v-for="option in typeSelectList" :key="option.typeId" :value="option.typeId">
            {{ option.typeName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item v-if="form.typeId === '3'" :labelCol="{ span: 2 }" :wrapper-col="{ span: 8 }" label="透出端" prop="penetrate">
        <a-radio-group name="radioGroup" @change="handleTypeIdOnChange"  v-model="form.penetrate" :default-value="1">
          <a-radio :value="1">
            APP
          </a-radio>
          <a-radio :value="2">
            小程序
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <!--      <a-form-model-item v-if="form.typeId === '3'" :labelCol="{ span: 2 }" :wrapper-col="{ span: 8 }" label="弹窗项目" prop="popUpProject">-->
      <!--        <a-checkbox-group :options="options" v-model="form.popUpProject" />-->
      <!--      </a-form-model-item>-->
      <a-form-model-item :labelCol="{ span: 2 }" :wrapper-col="{ span: 8 }" label="弹窗名称" prop="popupName">
        <a-input :disabled="disableType" placeholder="请输入弹窗名称" :max-length="10" v-model.trim="form.popupName" />
      </a-form-model-item>
      <a-form-model-item
        :labelCol="{ span: 2 }"
        :wrapper-col="{ span: 8 }"
        label="关联活动ID"
        v-if="form.typeId === '4' || form.typeId === '5' || form.typeId === '7'"
        prop="activityId"
      >
        <a-select
          show-search
          placeholder="请选择关联活动ID"
          :disabled="disableType"
          option-filter-prop="children"
          style="width: 100%"
          :filter-option="filterOption"
          v-model.trim="form.activityId"
          @blur="activityIdChange"
        >
          <a-select-option v-for="item in activities" :value="item.id">
            {{ item.activityTitle }}
          </a-select-option>
        </a-select>
        <!--        <a-input-->
        <!--          :disabled="disableType"-->
        <!--          placeholder="请输入关联活动ID"-->
        <!--          v-model.trim="form.activityId"-->
        <!--          @blur="activityIdChange"-->
        <!--        />-->
      </a-form-model-item>
      <a-form-model-item
        :labelCol="{ span: 2 }"
        :wrapper-col="{ span: 8 }"
        v-if="form.typeId == '3'"
        label="生效时间"
        prop="timeSlot"
      >
        <a-range-picker
          :show-time="{ format: 'HH:mm:ss' }"
          format="YYYY-MM-DD HH:mm:ss"
          :disabled="disableType"
          style="width: 100%"
          :placeholder="['开始时间', '结束时间']"
          v-model="form.timeSlot"
          @change="timeChange"
        />
      </a-form-model-item>
      <a-form-model-item
        :labelCol="{ span: 2 }"
        :wrapper-col="{ span: 20 }"
        v-if="form.typeId == '5'"
        label="弹窗图片"
        :required="true"
      >
        <template>
          <div class="display_flex">
            <div class="uploadView" v-for="(item, index) in form.projectFileList" :key="index">
              <div class="projectNameClass">{{ item.projectName }}</div>
              <div class="uploadImgClass">
                <a-upload
                  :disabled="disableType"
                  :action="IMG_API + '/oss/files'"
                  list-type="picture-card"
                  :file-list="item.imgList"
                  @preview="handlePreview"
                  @change="(value) => handleProjectFileChangeImg(index, value)"
                  :before-upload="imgBeforeProjectFileUpload"
                >
                  <div v-if="item.imgList.length < 1">
                    <a-icon type="plus" />
                    <div class="ant-upload-text">
                      Upload
                    </div>
                  </div>
                </a-upload>
              </div>
            </div>
          </div>
          <span><span style="margin-right: 5px;color: red">*</span>图片必须是600 * 1070、大小不能超过1MB，格式为PNG、JPG、gif。</span>
        </template>
      </a-form-model-item>
      <a-form-model-item
        :labelCol="{ span: 2 }"
        :wrapper-col="{ span: 8 }"
        v-if="form.typeId === '3' || form.typeId === '6' || form.typeId === '7'"
        label="弹窗图片"
        prop="lineImage"
      >
        <div class="lottie-img" v-if="form.linkType === 2">
          <div id="lottie_box" style="height: 200px; width: 200px">
          </div>
          <a-icon type="delete" v-if="!disableType" @click="deleteImg" class="delete-icon" />
        </div>
        <a-upload
          v-show="form.linkType === 1"
          name="file"
          list-type="picture-card"
          :action="IMG_API + '/oss/files'"
          class="avatar-uploader"
          :disabled="disableType"
          :file-list="form.lineImage"
          @change="handleChangeImg"
          @preview="handlePreview"
          :before-upload="imgBeforeUpload"
        >
          <div v-if="this.form.lineImage.length < 1">
            <a-icon type="plus" />
            <div class="ant-upload-text">上传图片</div>
          </div>
        </a-upload>
        <!--        <div class="upload_tip">{{ `支持格式：png/jpg/gif格式;大小不超过${form.typeId === '3' ? '500kb' : '3M'};尺寸为${form.typeId === '3' ? '580*720' : '750*850'}的图片` }}</div>-->
        <div class="upload_tip">{{ uploadImgHit }}</div>
      </a-form-model-item>
      <a-form-model-item :labelCol="{ span: 2 }" :wrapper-col="{ span: 8 }" v-if="form.typeId == '3'" label="跳转链接">
        <a-input :disabled="disableType" placeholder="请输入跳转链接" v-model.trim="form.mipLink" />
      </a-form-model-item>
      <a-form-model-item :labelCol="{ span: 2 }" :wrapper-col="{ span: 8 }" v-if="form.typeId == '3'" label="返回按钮">
        <a-radio-group v-model="form.showBack">
          <a-radio :value="0">
            不显示
          </a-radio>
          <a-radio :value="1">
            显示
          </a-radio>
        </a-radio-group>
        <div class="upload_tip">注：该弹窗如有【跳转链接】，并且“显示”返回按钮，请认真填写【弹窗名称】信息</div>
      </a-form-model-item>
      <a-form-model-item :labelCol="{ span: 2 }" :wrapper-col="{ span: 8 }" label="优先级" prop="priority">
        <a-input
          type="Number"
          oninput="if(value.length>5)value=value.slice(0,5)"
          max="99999"
          :disabled="disableType"
          placeholder="请输入优先级序号"
          v-model.trim="form.priority"
        />
        <div class="upload_tip">请注意，弹窗优先级不可重复</div>
      </a-form-model-item>
      <a-form-model-item
        :labelCol="{ span: 2 }"
        :wrapper-col="{ span: 8 }"
        v-if="form.typeId === '3' || form.typeId ==='6'"
        class="up_file_radio_box"
        label="用户限制"
        prop="userLimits"
      >
        <div class="up_file_radio">
          <a-radio-group name="radioGroup" :disabled="disableType" v-model="form.userLimits">
            <a-radio :value="0"> 全部用户 </a-radio>
            <a-radio :value="1"> 指定用户 </a-radio>
            <a-radio :value="2" v-if="form.penetrate === 1 && form.typeId === '3'"> 指定人群 </a-radio>
            <a-radio :value="3" v-if="form.penetrate === 1 && form.typeId === '3'"> 通行记录用户</a-radio>
          </a-radio-group>
          <a-button v-show="form.userLimits === 1 && checkCrowdType === true" class="downFile" @click="checkCrowd">
            查看人群
          </a-button>
        </div>
      </a-form-model-item>
      <a-form-model-item
        :labelCol="{ span: 2 }"
        :wrapper-col="{ span: 8 }"
        v-if="(form.userLimits === 1 && (form.typeId == '3') || (form.userLimits === 1 && form.typeId === '6'))"
        label="推送人群"
        prop="fileData"
      >
        <div class="up_file">
          <div class="up_file_width">
            <a-upload
              name="file"
              :file-list="form.fileData"
              :action="IMG_API + '/oss/files'"
              :show-file-list="false"
              :showUploadList="{ showRemoveIcon: !disableType }"
              @change="handleChangeFile"
              :before-upload="beforeUploadFile"
            >
              <a-button :disabled="disableType"> <a-icon type="upload" /> 点击上传 </a-button>
            </a-upload>
          </div>
          <a-button @click="downTemplate" class="downFile"> 下载模板 </a-button>
        </div>
        <div class="upload_tip">支持扩展名：.xlsx</div>
      </a-form-model-item>
      <a-form-model-item
        :labelCol="{ span: 2 }"
        :wrapper-col="{ span: 8 }"
        label="弹窗项目"
        prop="projectIds"
        v-if="(form.userLimits === 2 && (form.typeId == '3')) || form.typeId === '6' || form.penetrate === 2">
<!--        <a-select-->
<!--          v-model="form.projectIds"-->
<!--          :disabled="disableType"-->
<!--          mode="tags"-->
<!--          style="width: 100%"-->
<!--          placeholder="请选择项目"-->
<!--        >-->
<!--          <a-select-option v-for="item in projectList" :key="item.id">-->
<!--            {{ item.project_name }}-->
<!--          </a-select-option>-->
<!--        </a-select>-->
        <a-tree-select
          v-model="form.projectIds"
          style="width: 200px"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          placeholder="请选择所属项目"
          multiple
          allow-clear
          tree-default-expand-all
        >
          <a-tree-select-node :disabled="item.disabled" :value="item.id" :title="item.project_name" v-for="item in projectList" :key="item.id">
            <a-tree-select-node :key="arr.id" :value="arr.id" :title="arr.project_name" v-for="arr in item.child">
            </a-tree-select-node>
          </a-tree-select-node>
        </a-tree-select>
      </a-form-model-item>
      <a-form-model-item
        :labelCol="{ span: 2 }"
        :wrapper-col="{ span: 8 }"
        label="用户人群"
        prop="userCrowdsIds"
        v-if="form.userLimits === 2 && (form.typeId == '3')"
      >
        <a-select
          v-model="form.userCrowdsIds"
          :disabled="disableType"
          mode="tags"
          style="width: 100%"
          placeholder="请选择用户人群"
        >
          <a-select-option v-for="item in crowdsList" :key="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item :labelCol="{ span: 2 }" :wrapper-col="{ span: 8 }" label="状态" prop="popupStatus">
        <a-select
          v-model="form.popupStatus"
          placeholder="请选择"
          :disabled="disableType"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option v-for="option in statusList" :key="option.value" :value="option.value">
            {{ option.label }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 6, offset: 10 }">
        <a-button type="primary" v-show="disableType === false" :loading="onSubmitLoading" @click="handleOk">
          提交
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <a-modal :visible="previewVisible" :footer="null" @cancel="previewHandleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>
<script>
import lottie from 'lottie-web';
import * as api from '@/api/popup';
import { IMG_API, PRE_FILE_PATH } from '@/config/index';
import moment from 'moment';
import { projectName, findAllCrowd } from '../../../api/pointsManagement';
import { getProjectListByActivityId, selectAllActivity } from "../../../api/popup";
import {mapState} from "vuex";
const options = [
  { label: '和盈', value: 1 },
  { label: '八号线', value: 2 },
];
export default {
  data() {
    //校验弹窗图片
    let checkImg = (rule, value, callback) => {
      // 校验文件
      if (this.form.lineImage.length !== 0) {
        return callback();
      }
      // 不合法
      callback(new Error('请选择弹窗图片'));
    };
    // 校验推送文件
    let checkFile = (rule, value, callback) => {
      // 校验文件
      if (this.form.fileData.length !== 0) {
        return callback();
      }
      // 不合法
      callback(new Error('请选择推送文件'));
    };
    //校验时间
    let checkTimeSlot = (rule, value, callback) => {
      if (this.form.timeSlot.length !== 0) {
        return callback();
      }
      // 不合法
      callback(new Error('请选择生效时间'));
    };
    let checkPriority = (rule, value, callback) => {
      // 校验优先级
      if (Number(value) >= 0) {
        return callback();
      }
      // 不合法
      callback(new Error('格式不正确请重新输入'));
    };
    return {
      lottie:{},
      jsonOptions: {
        animationData: null, // 动画配置
        loop: true,
        autoplay: true,
        path: '' // 动画路径
      },
      options: options,
      checkSize: false, //校验图片大小
      checkFormat: false, //校验格式
      checkwh: false, //校验图片宽高
      previewImage: '', //预览图片地址
      previewVisible: false, //预览弹窗状态
      IMG_API: IMG_API, //获取上传域名
      PRE_FILE_PATH: PRE_FILE_PATH, //模板域名
      onSubmitLoading: false, //提交按钮loading状态
      disableType: true, //是否可以编辑状态
      checkCrowdType: false, //查看人群按钮状态
      crowdsList: [], // 所有人群信息
      statusList: [
        //状态下拉值
        { label: '禁用', value: 1 },
        { label: '启用', value: 0 },
      ],
      // 可配置的活动
      activities:[],
      typeSelectList: [], //弹窗类型
      form: {
        popupName: '', //弹窗名称
        penetrate: 1, // 透出端
        popUpProject: [],
        typeId: undefined, //弹窗类型
        mipLink: '', //跳转链接
        priority: '', //优先级
        userLimits: 0, //用户限制
        timeSlot: [], //时间
        lineImage: [], //弹窗图片
        fileData: [], //推送人群文件
        projectFileList: [],
        startTime: '', //开始时间
        endTime: '', //结束时间
        popupStatus: '', //状态
        projectIds: [], // 弹窗项目
        userCrowdsIds: [], // 用户人群
        showBack: 0, // 是否展示返回按钮，0:不展示，1:展示
        // 图片类型 1: 图片 2: json格式
        linkType: 1,
      },
      rules: {
        popupName: [{ required: true, message: '请输入弹窗名称', trigger: 'blur' }],
        penetrate: [{ required: true, message: '请选择透出端', trigger: 'change' }],
        popUpProject: [{ required: true, message: '请选择弹窗项目', trigger: 'change' }],
        fileData: [{ required: true, validator: checkFile, trigger: 'blur' }],
        popupStatus: [{ required: true, message: '请选择状态', trigger: 'change' }],
        lineImage: [{ required: true, validator: checkImg, trigger: 'blur' }],
        priority: [
          { required: true, message: '请输入优先级', trigger: 'blur' },
          { validator: checkPriority, trigger: 'blur' },
        ],
        userLimits: [{ required: true, message: '请选择用户限制', trigger: 'blur,change' }],
        typeId: [{ required: true, message: '请选择弹窗类型', trigger: 'change' }],
        timeSlot: [{ required: true, validator: checkTimeSlot, trigger: 'change' }],
        projectIds: [{ required: true, message: '请选择项目', trigger: 'change' }],
        userCrowdsIds: [{ required: true, message: '请选择用户人群', trigger: 'change' }],

        activityId: [{ required: true, message: '请输入关联活动ID', trigger: 'blur' }],
      },
    };
  },
  computed: {
    /**
     * 图片的格式大小要求
     */
    uploadImgHit() {
      // 图片格式
      let pictureFormat = 'png/jpg/gif'
      // 图片大小
      let pictureSize = ''
      // 图片像素
      let imagePixels = ''
      switch (this.form.typeId) {
        case '3':
          pictureSize = '500kb';
          imagePixels = '580*720';
          break;
        case '7':
          pictureSize = '20M';
          imagePixels = '';
          pictureFormat = `${pictureFormat}/json`
          break;
        default:
          pictureSize = '3M';
          imagePixels = '750*850';
          break;
      }
      return  `支持格式：${pictureFormat}格式;大小不超过${pictureSize} ${imagePixels ? `;尺寸为${imagePixels}` : '' } 的图片`;
    },
    ...mapState({
      projectList: (state) => {
        return state.common.projects.length && state.common.projects.map(item => {
          return {
            ...item,
            disabled: !!(item.child)
          }
        })
      }
    })
  },
  watch: {
    'form.penetrate': {
      async handler(val) {
        let projects = []
        await projectName().then((res) => {
          if (res.code === '200') {
            projects = res.data.items
          }
        });
        if (val === 1) {
          this.projectList = projects.filter(item => item.penetrate === '1' || item.penetrate === '3');
        } else {
          this.projectList = projects.filter(item => item.penetrate === '2' || item.penetrate === '3');
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    moment(moment()).unix();
    // 获取项目列表
    // this.getProjectList();
    // 获取所有人群
    this.getAllCrowds();
    // 获取所有可配置的活动
    this.getAllActivity()
    //判断需不需要获取详情
    if (this.$route.query.id) {
      if (this.$route.query.type === '1') {
        this.disableType = false;
      } else {
        this.disableType = true;
      }
      this.checkCrowdType = true;
      this.getDetail();
    } else {
      this.disableType = false;
    }
  },
  mounted() {
    this.getSelect();
  },
  methods: {
    // 删除弹窗Lottie图片
    deleteImg() {
        this.lottie.destroy()
        this.form.lineImage = []
        this.form.linkType  = 1
    },
    loadAnimation() {
      if(this.form.linkType === 2) {
        setTimeout(() => {
          this.lottie = lottie.loadAnimation({
            container: document.getElementById('lottie_box'),
            renderer: 'svg',
            loop: true,
            path: this.form.lineImage[0].response
              ? this.form.lineImage[0].response.redirect_uri
              : this.form.lineImage[0].url
          })
        },2000)
      }
    },
    /**
     * select 筛选
     */
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    // 获取所有可配置的活动
    async getAllActivity() {
      const res = await selectAllActivity();
      const { data, code } = res
      if(code === '200') {
        this.activities = data.map(item => {
          return {
            ...item,
            activityTitle: `${item.id } - ${item.activityTitle}`
          }
        })
      }
    },
    // 获取项目列表
    // getProjectList() {
    //   projectName().then((res) => {
    //     if (res.code === '200') {
    //       this.projectList = res.data.items.filter(item => item.penetrate === '1');
    //     }
    //   });
    // },
    // 获取所有人群信息
    getAllCrowds() {
      findAllCrowd().then((res) => {
        if (res.code === '200') {
          this.crowdsList = res.data;
        }
      });
    },
    moment,
    //校验优先级
    async priorityChange() {
      if (this.form.priority) {
        const res = await api.checkpriority({
          priority: this.form.priority,
          popupId: this.$route.query.id ? this.$route.query.id : '',
          penetrate: this.form.penetrate
        });
        if (!res.data) {
          this.form.priority = '';
          this.$message.error('优先级重复!');
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    // 透出端change事件
    handleTypeIdOnChange(e) {
      this.form.projectIds = []
      this.form.userLimits = 0
    },
    //日期选择器change时间
    timeChange(value, dateString) {
      this.form.timeSlot = value;
      this.$forceUpdate();
    },
    //获取详情
    async getDetail() {
      const res = await api.getpopupbypopupid({ popupId: this.$route.query.id });
      if (res.code === '200') {
        this.form = res.data;
        this.form.lineImage = [];
        this.form.fileData = [];
        this.form.timeSlot = [];
        //弹窗图片赋值
        var obj = {
          uid: '1',
          name: 'image.png',
          status: 'done',
          url: res.data.popupPic,
        };
        this.form.lineImage.push(obj);
        //弹窗文件回显
        res.data.excelName.forEach((item, index) => {
          var obj1 = {
            uid: index,
            name: item.name,
            status: 'done',
            url: item.url,
          };
          this.form.fileData.push(obj1);
        });
        // 如果是开工红包，回显弹窗图片
        if (this.form.typeId === "5") {
          this.form.projectFileList = res.data.popupProjectImgList.map((item, index) => {
            return {
              ...item,
              imgList: [{
                uid: index,
                status: 'done',
                url: item.projectImg,
              }]
            }
          })
        }
        // 生效时间数据回显
        let value = [
          moment(res.data.startTime, 'YYYY-MM-DD HH:mm:ss'),
          moment(res.data.endTime, 'YYYY-MM-DD HH:mm:ss'),
        ];
        this.form.timeSlot = value;
        // 项目回显
        this.form.projectIds =
          res.data.projectIds === '' || res.data.projectIds === null ? [] : res.data.projectIds.split(',');
        // 用户人群回显
        this.form.userCrowdsIds =
          res.data.userCrowdsIds === '' || res.data.userCrowdsIds === null ? [] : res.data.userCrowdsIds.split(',');
      }
      this.loadAnimation();
    },
    //获取弹窗类型
    async getSelect() {
      const res = await api.getSelectList();
      if (res.code === '200') {
        this.typeSelectList = res.data;
      }
    },
    //处理提交数据
    handleFun() {
      let obj = {};
      let fileList = [];
      // 判断用户限制是否是指定人群
      if (this.form.userLimits === 1 || this.form.typeId === '6') {
        fileList = this.form.fileData && this.form.fileData.length && this.form.fileData.map((item) => {
          if (item.response) {
            return item.response.redirect_uri;
          } else {
            return item.url;
          }
        });
      } else {
        fileList = [];
      }
      if (this.form.typeId == '3') {
        obj = {
          popupName: this.form.popupName,
          typeId: this.form.typeId,
          popupId: this.form.popupId ? this.form.popupId : null,
          mipLink: this.form.mipLink,
          priority: Number(this.form.priority),
          userLimits: this.form.userLimits,
          popupPic: this.form.lineImage[0].response
            ? this.form.lineImage[0].response.redirect_uri
            : this.form.lineImage[0].url,
          startTime: moment(this.form.timeSlot[0]).format('YYYY-MM-DD HH:mm:ss'),
          endTime: moment(this.form.timeSlot[1]).format('YYYY-MM-DD HH:mm:ss'),
          urls: fileList && fileList.length ? fileList : [],
          popupStatus: this.form.popupStatus,
          projectIds: this.form.projectIds.toString(), // 弹窗项目
          userCrowdsIds: this.form.userCrowdsIds.toString(), // 用户人群
          penetrate: this.form.penetrate, // 弹窗透出端
          showBack: this.form.showBack, // 是否显示返回
          linkType: this.form.linkType
        };
      } else if (this.form.typeId == '4') {
        //年终回馈
        obj = {
          popupName: this.form.popupName,
          typeId: this.form.typeId,
          activityId: this.form.activityId,
          popupId: this.form.popupId ? this.form.popupId : null,
          priority: Number(this.form.priority),
          popupStatus: this.form.popupStatus,
          linkType: this.form.linkType
        };
      } else {
        obj = {
          popupName: this.form.popupName,
          penetrate: 1,
          typeId: this.form.typeId,
          activityId: this.form.activityId,
          urls: fileList && fileList.length ? fileList : [],
          popupId: this.form.popupId ? this.form.popupId : null,
          priority: Number(this.form.priority),
          popupStatus: this.form.popupStatus,
          popupProjectImgList: this.form.projectFileList,
          projectIds: this.form.projectIds.toString(),
          popupPic: this.form.lineImage[0].response
            ? this.form.lineImage[0].response.redirect_uri
            : this.form.lineImage[0].url,
          linkType: this.form.linkType,
          userLimits: this.form.userLimits,
        };
      }
      return obj;
    },
    // 确定提交
    async handleOk() {
      if(this.form.typeId === '5'){
        try {
          if (this.form.projectFileList.length === 0) {
            this.$message.warning("当前关联的活动未关联项目！")
            throw new Error()
          } else {
            this.form.projectFileList.forEach(item => {
              if (item.imgList.length === 0) {
                this.$message.warning(`请上传${item.projectName}的弹窗图片！`)
                throw new Error()
              }
            })
          }
        } catch (err) {
          return false;
        }
      }
      // 规则校验
      let priorityType = await this.priorityChange();
      if (priorityType) {
        this.$refs.ruleForm.validate(async (valid) => {
          if (valid) {
            this.onSubmitLoading = true;
            // 判断是否是指定人群
            if (this.form.userLimits !== 2 && (this.form.typeId == '3')) {
              this.form.userCrowdsIds = '';
            }
            // 判断是否编辑
            if (this.$route.query.id) {
              this.addOldData();
            } else {
              this.addNewData();
            }
          } else {
          }
        });
      }
    },
    //添加新数据
    async addNewData() {
      let obj = this.handleFun();
      const res = await api.popupinsert(obj);
      this.onSubmitLoading = false;
      if (res.code === '200') {
        this.$message.success('添加成功!');
        this.$router.go(-1);
      }else{
        this.$message.error(res.msg);
      }
    },
    //添加旧数据
    async addOldData() {
      let obj = this.handleFun();
      const res = await api.popupupdate(obj);
      this.onSubmitLoading = false;
      if (res.code === '200') {
        this.$message.success('编辑成功!');
        this.$router.go(-1);
      }else{
        this.$message.error(res.msg);
      }
    },
    //下载模板
    downTemplate() {
      window.open(PRE_FILE_PATH + '/model/%E5%BC%B9%E7%AA%97%E4%B8%8A%E4%BC%A0%E7%94%A8%E6%88%B7id.xlsx');
    },
    //返回事件
    handleCancel() {
      this.$router.go(-1);
    },
    //查看人群
    checkCrowd() {
      this.$router.push({
        path: '/popupAdmin/viewCrowd',
        query: { id: this.$route.query.id, type: this.$route.query.type },
      });
    },
    //推送人群
    handleChangeFile({ fileList }) {
      if (fileList.length != 0) {
        fileList.forEach((file, index) => {
          let num = file.name.lastIndexOf('.');
          let type = file.name.substring(num + 1, file.name.length);
          const isJPG = type === 'xlsx';
          if (isJPG) {
            this.form.fileData = fileList;
            this.$forceUpdate();
          } else {
            fileList.splice(index, 1);
            this.$forceUpdate();
          }
        });
      } else {
        this.form.fileData = [];
        this.$forceUpdate();
      }
    },
    //推送人群上传文件之前校验文件类型
    beforeUploadFile(file) {
      let that = this;
      let index = file.name.lastIndexOf('.');
      let type = file.name.substring(index + 1, file.name.length);
      const isJPG = type === 'xlsx';
      if (!isJPG) {
        that.$message.error('请上传正确格式的文件!');
        return false;
      }
      setTimeout(() => {
        that.$message.success('上传成功!');
      }, 900);
      return isJPG;
    },
    //弹窗图片预览关闭弹窗
    previewHandleCancel() {
      this.previewVisible = false;
    },
    //预览
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    // 弹窗类型change
    typeChange() {
      this.form.timeSlot = [];
      this.form.userLimits = 0;
      this.form.fileData = [];
      this.form.userCrowdsIds = [];
      this.form.projectIds = [];
      this.form.popupStatus = '';
      this.form.mipLink = '';
      this.form.priority = '';
      this.form.popupName = '';
      this.form.lineImage = [];
      this.form.projectFileList = [];
    },

    //开工红包-
    handleProjectFileChangeImg(index, { fileList }) {
      this.form.projectFileList[index].imgList = fileList
      if (this.form.projectFileList[index].imgList.length > 0 && this.form.projectFileList[index].imgList[0].status ==="done") {
        this.form.projectFileList[index].projectImg = fileList[0].response.redirect_uri;
        this.$forceUpdate();
      } else {
        this.form.projectFileList[index].projectImg = ''
        this.$forceUpdate();
      }
    },
    async handleProjectFilePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    // 项目图片校验
    imgBeforeProjectFileUpload(file) {
      let index = file.name.lastIndexOf('.');
      let type = file.name.substring(index + 1, file.name.length);
      const isJPG = type === 'png' || type === 'jpg' || type === 'gif';
      this.checkFormat = isJPG;
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isJPG) {
        this.$message.error('上传图片只能是jpg、png、gif格式!');
        return new Promise(function(resolve, reject){return reject});
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过1MB!');
        return new Promise(function(resolve, reject){return reject});
      }
      const checkwh = new Promise(function (resolve, reject) {
        const width = 600; // 限制图片尺寸
        const height = 1070;
        const URL = window.URL || window.webkitURL;
        const img = new Image();
        img.onload = function () {
          const valid = img.width === width && img.height === height;
          valid ? resolve() : reject();
        };
        img.src = URL.createObjectURL(file);
      }).then(
        () => {
          return file;
        },
        () => {
          this.$message.error('图片不规范，请按要求上传');
          return Promise.reject();
        }
      );
      this.checkwh = checkwh;
      return isJPG && isLt2M && checkwh;
    },
    //弹窗图片
    handleChangeImg({ fileList }) {
      if (fileList.length != 0) {
        if (this.checkFormat && this.checkSize && this.checkwh) {
          this.form.lineImage = fileList;
          if(fileList[0]?.response?.redirect_uri) {
            this.loadAnimation();
          }
          this.$forceUpdate();
        }
      } else {
        this.form.lineImage = fileList;
        this.loadAnimation();
        this.$forceUpdate();
      }
    },
    //上传文件之前校验图片大小
    imgBeforeUpload(file) {
      let that = this
      let index = file.name.lastIndexOf('.');
      let type = file.name.substring(index + 1, file.name.length);
      this.form.linkType = type === 'json' ? 2 : 1
      let checkwh = false
      const isJPG = type === 'png' || type === 'jpg' || type === 'gif' || type === 'jpeg' || type === 'json';
      this.checkFormat = isJPG;
      let isLt2M = true
      if (this.form.typeId === '3') {
        isLt2M = file.size / 1024 / 1024 < 0.5;
      }
      if (this.form.typeId === '6') {
        isLt2M = file.size / 1024 / 1024 <= 3
      }
      if (this.form.typeId === '7') {
        isLt2M = file.size / 1024 / 1024 <= 20
      }
      this.checkSize = isLt2M;
      if (!isJPG) {
        this.$message.error('上传图片只能是jpg、png、gif、json格式!');
        return false;
      }
      if (!isLt2M) {
        this.$message.error(`上传图片大小不能超过${this.form.typeId === '3' ? '500kb' : this.form.typeId === '7' ? '20M' : '3M'}!`);
        return false;
      }
      if (this.form.typeId === '7') {
        checkwh = true
      } else {
        checkwh = new Promise(function (resolve, reject) {
          const width = that.form.typeId === '3' ? 580 : 750; // 限制图片尺寸
          const height = that.form.typeId === '3' ? 720 : 850;
          const URL = window.URL || window.webkitURL;
          const img = new Image();
          img.onload = function () {
            const valid = img.width === width && img.height === height;
            valid ? resolve() : reject();
          };
          img.src = URL.createObjectURL(file);
        }).then(
          () => {
            return file;
          },
          () => {
            this.$message.error('图片不规范，请按要求上传');
            return Promise.reject();
          }
        );
      }
      this.checkwh = checkwh;
      return isJPG && isLt2M && checkwh;
    },
    // 活动change事件
    activityIdChange(e) {
      const activityId = e.target.value
      if (this.form.typeId === '5') {
        // this.form.projectFileList.push({
        //   id: '1',
        //   projectName: "枫烨园",
        //   projectImg: []
        // })
        getProjectListByActivityId(activityId).then(res => {
          const { code, data, msg } = res
          if (code === '200') {
            if (data.length === 0) {
              this.$message.warning("当前活动没有关联项目，请先关联项目！")
              return false;
            }
            this.form.projectFileList = data.map(item => {
              return {
                ...item,
                projectId: item.id,
                imgList: [],
                projectImg: ''
              }
            })
          } else {
            this.$message.error(msg)
          }
        })
      }

    }
  },
};
</script>
<style lang="scss" scoped>
.ant-form-item {
  display: flex;
}
.upload_tip {
  line-height: 20px;
  font-size: 12px;
}
.up_file_width {
  min-width: 200px;
}
.up_file {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.up_file_radio {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.up_file_radio_box {
  display: flex;
  align-items: center;
}
.backClass {
  display: flex;
  justify-content: flex-end;
}
.display_flex {
  display: flex;
  flex-wrap: wrap;
}
.uploadView {
  /*flex: 0 0 33%;*/
  border: 1px solid #D4D4D4;
  width: 200px;
  margin-right: 10px;
  margin-bottom: 10px;
  .projectNameClass {
    padding: 5px;
  }
  .uploadImgClass {
    display: grid;
    justify-content: center;
    align-items: center;
  }
}
.lottie-img {
  width: 200px;
  height: 200px;
  position: relative;
  .delete-icon {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 100
  }
}
</style>

